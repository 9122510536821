import {mapGetters} from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import ApiService from "@/core/services/api.service";
import PromoCodesApiService from "@/core/services/api.service.promo.codes";

export default {
    props: {
        modal: {
            default: false
        },
        value: Boolean
    },

    name: "promo-code",
    data() {
        return {
            valid: false,
            isSaving: false,
            promoCodeId: null,
            promoCodeApiInitiated: false,
            shopsToAdd: null,
            shops: [],
            shopsAreLoading: false,
            searchShops: "",
            addShopsDisable: false,
            promoCodeBulkInsert: {
                numberOfPromoCodesToGenerate: null,
                pricePerPromoCode: null,
                quantityPerPromoCode: null,
                expirationDatePerPromoCode: null,
                shops: []
            },
            requiredRule: [v => !!v || "Field is required"]
        };
    },
    async created() {
        await PromoCodesApiService.init();
        this.promoCodeApiInitiated = true;
        let title = "Promo codes bulk insert";
        this.$store.dispatch(SET_BREADCRUMB, [{title: title}]);
        this.loadShopsDropDown();
    },

    components: {
        ConfirmDialog
    },
    methods: {
        deleteShopFromList(item) {
            this.$log.debug("Shop to be deleted: " + item);
            const newArray = this.promoCode.shops.filter(obj => item.shopId !== obj.shopId);
            this.promoCodeBulkInsert.shops = newArray;
            this.$log.debug("new array: " + newArray);
        },
        addShopsToPromoCode() {
            this.$log.debug("shopsToAdd: " + this.shopsToAdd);
            this.$log.debug("this.shopsToAdd: " + JSON.stringify(this.shopsToAdd));
            this.addShopsDisable = true;
            let shops = [];
            this.shopsToAdd.forEach(function (shop) {
                let singleObj = {};
                console.log(shop);
                // this.$log.debug("shop['id']: " + shop['id']);
                // this.$log.debug("shop['name']: " + shop['name']);
                singleObj['shopId'] = shop['id'];
                singleObj['shopName'] = shop['name'];
                shops.push(singleObj);
            });
            this.promoCodeBulkInsert.shops = shops;
            this.addShopsDisable = false;
        },
        loadShopsDropDown() {
            // Items have already been loaded
            if (this.shops.length > 0) return;

            // Items have already been requested
            if (this.shopsAreLoading) return;

            this.shopsAreLoading = true;
            ApiService.query("api/shops/dropdown-list")
                .then(res => {
                    this.shops = res.data;
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => (this.shopsAreLoading = false));
        },
        previousState() {
            this.$router.go(-1);
        },
        async validateAndSave() {
            if (this.$refs.form.validate()) {
                this.savePromoCode(this.promoCodeBulkInsert);
            }
        },

        savePromoCode(promoCodeBulkInsert) {
            this.isSaving = true;
            this.loading = true;
            this.promoCodeBulkInsert.shops = [];
            this.addShopsToPromoCode();
            PromoCodesApiService.post(`/GeneratePromoCodes`, promoCodeBulkInsert)
                .then(response => {
                    this.$log.debug("Promos codes request sent: " + response);
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => {
                    this.loading = false;
                    this.isSaving = false;
                    this.previousState();
                });
        }
    },
    computed: {
        ...mapGetters(["currentUserPersonalInfo"]),
        ...mapGetters(["currentUser"]),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                if (!value) {
                    this.$emit("input", value);
                }
            }
        },
        expirationDateFormatted: {
            get() {
                if (this.promoCodeBulkInsert.expirationDatePerPromoCode == null || this.promoCodeBulkInsert.expirationDatePerPromoCode === "")
                    return null;
                return moment
                    .utc(this.promoCodeBulkInsert.expirationDatePerPromoCode)
                    .local()
                    .format("yyyy-MM-DD HH:mm:ss");
            },
            set(value) {
                this.promoCodeBulkInsert.expirationDatePerPromoCode = value;
            }
        },
    }
};
